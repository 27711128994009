import React, { FC, SVGProps } from 'react';

interface OctagonExclamationProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const OctagonExclamation: FC<OctagonExclamationProps> = ({ title = 'Report spam', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M482.8 157.1l-127.1-127.9C346.4 20.8 334.1 16 323 16H188.9c-11.84 0-23.32 4.805-31.76 13.24L29.24 157.2C20.8 165.6 16 177 16 188.1v134.2c0 11.84 4.805 23.32 13.24 31.76l127.1 127.9C165.6 491.2 177 496 188.1 496h134.2c11.84 0 23.32-4.805 31.76-13.24l127.9-127.1C491.2 346.4 496 334.1 496 323V188.9C496 177 491.2 165.5 482.8 157.1zM448 321.7L321.8 448H190.3L64 321.8V190.3L190.2 64h131.4L448 190.2V321.7zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z"></path>
  </svg>
);

export default OctagonExclamation;
