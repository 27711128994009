import React, { FC, SVGProps } from 'react';

interface BanProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const Ban: FC<BanProps> = ({ title = 'Block', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM48 256c0-48.71 16.95-93.47 45.11-128.1l291.9 291.9C349.5 447 304.7 464 256 464C141.3 464 48 370.7 48 256zM418.9 384.1L127 93.11C162.5 64.95 207.3 48 256 48c114.7 0 208 93.31 208 208C464 304.7 447 349.5 418.9 384.1z"></path>
  </svg>
);

export default Ban;
