import React, { FC, SVGProps } from 'react';

interface ArrowDownShortWideProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const ArrowDownShortWide: FC<ArrowDownShortWideProps> = ({ title = 'Sort ascending', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M312 96h48c13.25 0 23.1-10.75 23.1-24S373.2 48 360 48h-48C298.8 48 288 58.75 288 72S298.8 96 312 96zM312 224h111.1c13.25 0 24-10.75 24-24s-10.75-24-24-24H312C298.8 176 288 186.8 288 200S298.8 224 312 224zM551.1 432H312c-13.25 0-24 10.75-24 24S298.8 480 312 480h239.1c13.25 0 24-10.75 24-24S565.2 432 551.1 432zM312 352h175.1c13.25 0 24-10.75 24-24s-10.75-24-24-24H312C298.8 304 288 314.8 288 328S298.8 352 312 352zM206.4 335.1L152 394.9V56.02C152 42.76 141.3 32 128 32S104 42.76 104 56.02v338.9l-54.37-58.95c-4.719-5.125-11.16-7.719-17.62-7.719c-5.812 0-11.66 2.094-16.28 6.375c-9.75 8.977-10.34 24.18-1.344 33.94l95.1 104.1c9.062 9.82 26.19 9.82 35.25 0l95.1-104.1c9-9.758 8.406-24.96-1.344-33.94C230.5 325.5 215.3 326.2 206.4 335.1z"></path>
  </svg>
);

export default ArrowDownShortWide;
