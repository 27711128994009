import React, { FC, SVGProps } from 'react';

interface ArrowUpWideShortProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const ArrowUpWideShort: FC<ArrowUpWideShortProps> = ({ title = 'Sort descending', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M551.1 47.67H312c-13.25 0-24 10.76-24 24.02c0 13.26 10.75 24.02 24 24.02h239.1c13.25 0 24-10.76 24-24.02C575.1 58.43 565.2 47.67 551.1 47.67zM360 431.1h-48c-13.25 0-24 10.76-24 24.02S298.8 480 312 480h48c13.25 0 23.1-10.76 23.1-24.02S373.2 431.1 360 431.1zM423.1 303.9H312c-13.25 0-24 10.76-24 24.02s10.75 24.02 24 24.02h111.1c13.25 0 24-10.76 24-24.02S437.2 303.9 423.1 303.9zM487.1 175.8H312c-13.25 0-24 10.76-24 24.02s10.75 24.02 24 24.02h175.1c13.25 0 24-10.76 24-24.02S501.2 175.8 487.1 175.8zM145.6 39.37c-9.062-9.82-26.19-9.82-35.25 0L14.38 143.4c-9 9.758-8.406 24.96 1.344 33.94C20.35 181.7 26.19 183.8 32 183.8c6.469 0 12.91-2.594 17.62-7.719L104 117.1v338.9C104 469.2 114.8 480 128 480S152 469.2 152 455.1V117.1l54.37 58.95C215.3 185.8 230.5 186.5 240.3 177.4C250 168.4 250.6 153.2 241.6 143.4L145.6 39.37z"></path>
  </svg>
);

export default ArrowUpWideShort;
