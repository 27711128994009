import React, { FC, SVGProps } from 'react';

interface MessagePenProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const MessagePen: FC<MessagePenProps> = ({ title = 'Give feedback', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M325.7 117.3c-7.031-7.031-18.45-7.031-25.47 0l-18.23 18.23l48.01 48.01l18.24-18.24c7.029-7.035 7.029-18.44 0-25.47L325.7 117.3zM174.4 243.1C172.7 244.7 171.6 246.9 171.1 249.2l-9.521 47.61C160.8 301 164.5 304.7 168.7 303.9l47.61-9.52c2.322-.4687 4.455-1.609 6.131-3.281l90.58-90.57L264.1 152.5L174.4 243.1zM447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.836 11.02 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM464 352c0 8.75-7.25 16-16 16h-160l-80 60v-60H64c-8.75 0-16-7.25-16-16V64c0-8.75 7.25-16 16-16h384c8.75 0 16 7.25 16 16V352z"></path>
  </svg>
);

export default MessagePen;
