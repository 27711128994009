import React, { FC, SVGProps } from 'react';

interface MessageExclamationProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const MessageExclamation: FC<MessageExclamationProps> = ({ title = 'Unread messages', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M257.5 272c-17.67 0-32 14.33-32 32s14.33 32 32 32c17.67 0 32-14.33 32-32S275.2 272 257.5 272zM257.5 240c13.25 0 24-10.75 24-24v-112c0-13.25-10.75-24-24-24s-24 10.75-24 24v112C233.5 229.3 244.3 240 257.5 240zM447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.836 11.02 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM464 352c0 8.75-7.25 16-16 16h-160l-80 60v-60H64c-8.75 0-16-7.25-16-16V64c0-8.75 7.25-16 16-16h384c8.75 0 16 7.25 16 16V352z"></path>
  </svg>
);

export default MessageExclamation;
