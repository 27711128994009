import React, { FC, SVGProps } from 'react';

interface LogoDarkProps extends SVGProps<SVGSVGElement> {}

const LogoDark: FC<LogoDarkProps> = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1914.89 482.71"
    role="img"
    aria-labelledby="logo-title"
    {...rest}
  >
    <title id="logo-title">babel chat - Meet and chat with people from around the world.</title>
    <g>
      <g>
        <path d="M44.36,371,93.17,245.49a39.57,39.57,0,0,1,50.31-22.87c47.33,17,132.91,42.57,204.09,35.15l17.26,83.46a39.55,39.55,0,0,1-35.17,47.42c-48.22,4.35-125.88,9.2-180,1.91a39,39,0,0,0-27.47,6.83L0,482.71Z" fill="#10b981"/>
        <path d="M326.83,163.06l12,71.33S240.7,251.06,131.6,196.1l13.94-65.85S225.11,177.83,326.83,163.06Z" fill="#10b981"/>
        <path d="M302.76,150.38l-6.53-63.85h0c-42.81,4.35-103.76-20.32-103.76-20.32l-14.23,50.61S215.59,148.57,302.76,150.38Z" fill="#10b981"/>
        <circle cx="253.52" cy="31.38" r="31.38" fill="#10b981"/>
      </g>
      <rect x="133.4" y="276.73" width="25.25" height="33.74" rx="5.71" fill="#111827"/>
      <rect x="204.73" y="301.58" width="25.25" height="33.74" rx="5.71" fill="#111827"/>
      <rect x="276.07" y="322.98" width="25.25" height="33.74" rx="5.71" fill="#111827"/>
    </g>
    <g>
      <g>
        <path d="M599.7,227.54a43,43,0,0,1-6.82,23.91,47.5,47.5,0,0,1-18.36,16.45,54,54,0,0,1-25.16,5.94H464.6V94.2h86.52q12.68,0,21.89,6.71a44,44,0,0,1,14.18,17.2A51,51,0,0,1,592.12,140a47.51,47.51,0,0,1-6.82,24.79,42.07,42.07,0,0,1-19,17,44.94,44.94,0,0,1,24.42,17Q599.72,211.09,599.7,227.54ZM482.31,109.63v66.3h61.75a27.08,27.08,0,0,0,15.67-4.68,34.75,34.75,0,0,0,14.93-28.47,36,36,0,0,0-3.8-16.57,31.88,31.88,0,0,0-10.36-12,25.67,25.67,0,0,0-14.93-4.56ZM582,224.51a35.87,35.87,0,0,0-4-16.83,34.31,34.31,0,0,0-11-12.52,27.05,27.05,0,0,0-15.81-4.81H482.31v68.06h67.05a29.3,29.3,0,0,0,16.44-4.81,36.23,36.23,0,0,0,11.77-12.52A32.69,32.69,0,0,0,582,224.51Z" fill="#d1d5db"/>
        <path d="M685,94.2h14.93l74.64,179.64H755.81l-22.45-56.17H651.24l-22.45,56.17h-19Zm42.85,109.56L692.3,115l-35.48,88.81Z" fill="#d1d5db"/>
        <path d="M935.45,227.54a43,43,0,0,1-6.82,23.91,47.5,47.5,0,0,1-18.36,16.45,54,54,0,0,1-25.16,5.94H800.35V94.2h86.52q12.68,0,21.89,6.71a44.12,44.12,0,0,1,14.18,17.2A51.16,51.16,0,0,1,927.86,140,47.42,47.42,0,0,1,921,164.79a42,42,0,0,1-19,17,44.87,44.87,0,0,1,24.41,17Q935.48,211.09,935.45,227.54ZM818.05,109.63v66.3H879.8a27.11,27.11,0,0,0,15.68-4.68,34.77,34.77,0,0,0,14.92-28.47,36,36,0,0,0-3.79-16.57,31.91,31.91,0,0,0-10.37-12,25.63,25.63,0,0,0-14.93-4.56Zm99.69,114.88a35.75,35.75,0,0,0-4-16.83,34.38,34.38,0,0,0-11-12.52,27.05,27.05,0,0,0-15.81-4.81H818.05v68.06h67.06a29.32,29.32,0,0,0,16.44-4.81,36.32,36.32,0,0,0,11.77-12.52A32.68,32.68,0,0,0,917.74,224.51Z" fill="#d1d5db"/>
        <path d="M1089.55,258.16v15.68H968.85V94.2h118.42v15.69H986.56v64.77h87.79v14.93H986.56v68.57Z" fill="#d1d5db"/>
        <path d="M1122.93,273.84V94.2h17.73v164h103.73v15.68Z" fill="#d1d5db"/>
        <path d="M1256.79,182.5a93.56,93.56,0,0,1,22.9-61.1A83.26,83.26,0,0,1,1307,100.65q16.05-7.71,36.54-7.71,24.56,0,42.14,11t26.18,28.21l-22.27,14.93c-3.21-7.25-7.36-13-12.51-17.2a45.62,45.62,0,0,0-16.83-8.86,66,66,0,0,0-17.7-2.53q-13.93,0-24.55,5.69a55,55,0,0,0-17.85,15.06,65.3,65.3,0,0,0-10.75,21,79.3,79.3,0,0,0-3.54,23.27,75.19,75.19,0,0,0,4.18,25.06,67.12,67.12,0,0,0,11.9,21.12,56.8,56.8,0,0,0,18.2,14.55,50.6,50.6,0,0,0,23.15,5.31,59.09,59.09,0,0,0,18.23-3,52.59,52.59,0,0,0,17.32-9.62,45.35,45.35,0,0,0,12.53-16.7l23.54,13.16a54.4,54.4,0,0,1-17.08,22.77,84.1,84.1,0,0,1-26.44,14.17,93.25,93.25,0,0,1-29.12,4.81,74.31,74.31,0,0,1-34.14-8,89.11,89.11,0,0,1-27.2-21.26A99.12,99.12,0,0,1,1263.11,216,93.28,93.28,0,0,1,1256.79,182.5Z" fill="#10b981"/>
        <path d="M1588.74,94.2V273.84H1560.4V194.65h-90.59v79.19h-28.59V94.2h28.59v75.15h90.59V94.2Z" fill="#10b981"/>
        <path d="M1681.09,94.2h26.83l69.83,179.64H1747.9L1730.32,225H1658.5l-17.63,48.83H1611ZM1722.78,204l-28.28-78.69L1666.07,204Z" fill="#10b981"/>
        <path d="M1914.89,119.25h-59.72V273.84h-28.59V119.25h-60v-25h148.27Z" fill="#10b981"/>
      </g>
      <g>
        <path d="M509.66,347.34V317.92l-12.84,22.85h-2.19l-12.88-22.85v29.42h-3.51V311.2h3.6l13.84,24.74,13.93-24.74h3.55v36.14Z" fill="#d1d5db"/>
        <path d="M545.86,344.18v3.16H522V311.2h23.38v3.16H525.54v13h17.32v3H525.54v13.79Z" fill="#d1d5db"/>
        <path d="M576.26,344.18v3.16H552.44V311.2h23.38v3.16H556v13h17.32v3H556v13.79Z" fill="#d1d5db"/>
        <path d="M608,314.36H595.39v33H591.9v-33H579.26V311.2H608Z" fill="#d1d5db"/>
        <path d="M634.09,311.2H637l14.73,36.14h-3.7L643.64,336h-16.2L623,347.34h-3.75Zm8.45,22-7-17.86-7,17.86Z" fill="#d1d5db"/>
        <path d="M660.37,317.77v29.57h-3.51V311.2h2.9L683,341.33V311.25h3.49v36.09h-3.24Z" fill="#d1d5db"/>
        <path d="M695.42,347.34V311.2h12a17.16,17.16,0,0,1,9.41,2.42,15.25,15.25,0,0,1,5.66,6.52,21.24,21.24,0,0,1,1.9,9.08,20.82,20.82,0,0,1-2.07,9.56,15.12,15.12,0,0,1-5.89,6.32,17.53,17.53,0,0,1-9,2.24Zm25.51-18.12a17.72,17.72,0,0,0-1.57-7.68,12.28,12.28,0,0,0-4.56-5.27,13.37,13.37,0,0,0-7.34-1.91h-8.54v29.82h8.54a13.09,13.09,0,0,0,7.38-2,12.59,12.59,0,0,0,4.55-5.38A18,18,0,0,0,720.93,329.22Z" fill="#d1d5db"/>
        <path d="M741.5,329a19.42,19.42,0,0,1,4.4-12.29,15.67,15.67,0,0,1,5.3-4.17,15.93,15.93,0,0,1,7.13-1.55,14.37,14.37,0,0,1,8.11,2.21,13.37,13.37,0,0,1,5,5.68l-2.75,1.73a10.46,10.46,0,0,0-2.89-3.79,11.31,11.31,0,0,0-3.72-2,13.45,13.45,0,0,0-3.92-.61,11.91,11.91,0,0,0-5.64,1.3,12.56,12.56,0,0,0-4.12,3.46,15.62,15.62,0,0,0-2.52,4.84,17.63,17.63,0,0,0-.85,5.42,16.84,16.84,0,0,0,1,5.75,15.58,15.58,0,0,0,2.79,4.86,13.73,13.73,0,0,0,4.23,3.36,11.3,11.3,0,0,0,5.26,1.24,12.87,12.87,0,0,0,8-2.89,10.56,10.56,0,0,0,2.92-3.83l2.9,1.53a11.43,11.43,0,0,1-3.39,4.58,16.21,16.21,0,0,1-5.12,2.85,16.81,16.81,0,0,1-5.51,1,14.13,14.13,0,0,1-6.7-1.6,17.12,17.12,0,0,1-5.26-4.28A20.2,20.2,0,0,1,741.5,329Z" fill="#d1d5db"/>
        <path d="M806.07,311.2v36.14h-3.44v-16.9h-21v16.9h-3.5V311.2h3.5v16.08h21V311.2Z" fill="#d1d5db"/>
        <path d="M825.94,311.2h2.95l14.73,36.14h-3.7L835.49,336h-16.2l-4.43,11.3h-3.75Zm8.45,22-7-17.86-7,17.86Z" fill="#d1d5db"/>
        <path d="M870.48,314.36H857.85v33h-3.49v-33H841.72V311.2h28.76Z" fill="#d1d5db"/>
        <path d="M898.75,311.46H902l5,12.82,5.05-12.82h3.35l-6.2,15.42L916,343.21l12.84-32h3.79l-15,36.14h-3.09l-7.44-17.87-7.5,17.87h-3l-15-36.14h3.75l12.88,32,6.74-16.33Z" fill="#d1d5db"/>
        <path d="M937.64,347.34V311.2h3.51v36.14Z" fill="#d1d5db"/>
        <path d="M975.2,314.36H962.56v33h-3.49v-33H946.43V311.2H975.2Z" fill="#d1d5db"/>
        <path d="M1008.45,311.2v36.14H1005v-16.9H984v16.9h-3.5V311.2H984v16.08h21V311.2Z" fill="#d1d5db"/>
        <path d="M1030.08,347.34V311.2h14.68a9.32,9.32,0,0,1,4.22,1,10.57,10.57,0,0,1,3.32,2.62,12.9,12.9,0,0,1,2.19,3.67,10.84,10.84,0,0,1,.81,4.09,12.54,12.54,0,0,1-1.28,5.57,10.8,10.8,0,0,1-3.57,4.25,9.05,9.05,0,0,1-5.39,1.63h-11.48v13.34Zm3.5-16.49h11.33a5.91,5.91,0,0,0,3.64-1.17,7.89,7.89,0,0,0,2.41-3.06,9.85,9.85,0,0,0,.84-4.07,8.73,8.73,0,0,0-1-4.12,8.28,8.28,0,0,0-2.62-3,6.28,6.28,0,0,0-3.57-1.09h-11Z" fill="#d1d5db"/>
        <path d="M1085,344.18v3.16h-23.82V311.2h23.38v3.16h-19.87v13H1082v3h-17.31v13.79Z" fill="#d1d5db"/>
        <path d="M1105.13,347.59a14.86,14.86,0,0,1-6.78-1.53,16.29,16.29,0,0,1-5.25-4.14,19.94,19.94,0,0,1-3.4-5.91,19.57,19.57,0,0,1,0-13.63,18.64,18.64,0,0,1,3.53-5.86,17.12,17.12,0,0,1,5.31-4.07,15.37,15.37,0,0,1,13.39.07,16.57,16.57,0,0,1,5.21,4.23,20.36,20.36,0,0,1,3.38,5.91,19.11,19.11,0,0,1,1.2,6.66,19.36,19.36,0,0,1-1.26,6.9,19.1,19.1,0,0,1-3.49,5.85,16.83,16.83,0,0,1-5.29,4.05A15,15,0,0,1,1105.13,347.59ZM1092,329.27a17.22,17.22,0,0,0,.93,5.68,15.66,15.66,0,0,0,2.71,4.83,13.12,13.12,0,0,0,4.16,3.36,12.12,12.12,0,0,0,10.8-.06,13.23,13.23,0,0,0,4.14-3.46,16.13,16.13,0,0,0,2.62-4.85,17.26,17.26,0,0,0,.89-5.5,16.48,16.48,0,0,0-1-5.65,15.65,15.65,0,0,0-2.71-4.84,13.09,13.09,0,0,0-4.18-3.35,12,12,0,0,0-10.7.05,13,13,0,0,0-4.15,3.43,16.26,16.26,0,0,0-2.65,4.86A16.74,16.74,0,0,0,1092,329.27Z" fill="#d1d5db"/>
        <path d="M1128.35,347.34V311.2H1143a9.28,9.28,0,0,1,4.21,1,10.57,10.57,0,0,1,3.32,2.62,12.94,12.94,0,0,1,2.2,3.67,11,11,0,0,1,.8,4.09,12.67,12.67,0,0,1-1.27,5.57,10.91,10.91,0,0,1-3.58,4.25,9.05,9.05,0,0,1-5.39,1.63h-11.48v13.34Zm3.5-16.49h11.33a5.91,5.91,0,0,0,3.64-1.17,7.8,7.8,0,0,0,2.41-3.06,9.86,9.86,0,0,0,.85-4.07,8.74,8.74,0,0,0-1-4.12,8.28,8.28,0,0,0-2.62-3,6.28,6.28,0,0,0-3.57-1.09h-11Z" fill="#d1d5db"/>
        <path d="M1159.4,347.34V311.2h3.51v33h20.46v3.16Z" fill="#d1d5db"/>
        <path d="M1212.34,344.18v3.16h-23.83V311.2h23.38v3.16H1192v13h17.32v3H1192v13.79Z" fill="#d1d5db"/>
        <path d="M1231.66,347.34V311.2h23.27v3.16h-19.77v13.28h16.68v2.95h-16.68v16.75Z" fill="#d1d5db"/>
        <path d="M1261.08,347.34V311.2h15a9.13,9.13,0,0,1,4.19,1,10.8,10.8,0,0,1,3.32,2.62,12.49,12.49,0,0,1,2.2,3.67,11.42,11.42,0,0,1,.78,4.09,12.21,12.21,0,0,1-1,4.89,11,11,0,0,1-2.74,3.87,9,9,0,0,1-4.17,2.08l8.69,13.95h-3.95L1275.06,334h-10.48v13.34Zm3.5-16.49h11.58a5.91,5.91,0,0,0,3.64-1.17,7.89,7.89,0,0,0,2.41-3.08,10,10,0,0,0,.84-4.05,8.74,8.74,0,0,0-1-4.05,8.51,8.51,0,0,0-2.6-3,6.14,6.14,0,0,0-3.61-1.14h-11.29Z" fill="#d1d5db"/>
        <path d="M1308,347.59a14.93,14.93,0,0,1-6.79-1.53,16.36,16.36,0,0,1-5.24-4.14,19.73,19.73,0,0,1-3.4-5.91,19.47,19.47,0,0,1-1.2-6.74,19.22,19.22,0,0,1,1.25-6.89,18.62,18.62,0,0,1,3.52-5.86,17.16,17.16,0,0,1,5.32-4.07,15.37,15.37,0,0,1,13.39.07,16.8,16.8,0,0,1,5.21,4.23,20.11,20.11,0,0,1,3.37,5.91,19.12,19.12,0,0,1,1.21,6.66,19.36,19.36,0,0,1-1.26,6.9,19.13,19.13,0,0,1-3.5,5.85,16.68,16.68,0,0,1-5.28,4.05A15,15,0,0,1,1308,347.59Zm-13.13-18.32a17.22,17.22,0,0,0,.94,5.68,15.84,15.84,0,0,0,2.7,4.83,13.27,13.27,0,0,0,4.17,3.36,12.1,12.1,0,0,0,10.79-.06,13.15,13.15,0,0,0,4.15-3.46,16.13,16.13,0,0,0,2.62-4.85,17.54,17.54,0,0,0,.89-5.5,16.72,16.72,0,0,0-.95-5.65,15.65,15.65,0,0,0-2.71-4.84,13.2,13.2,0,0,0-4.18-3.35,12,12,0,0,0-10.7.05,13,13,0,0,0-4.16,3.43,16.46,16.46,0,0,0-2.64,4.86A16.75,16.75,0,0,0,1294.83,329.27Z" fill="#d1d5db"/>
        <path d="M1362.59,347.34V317.92l-12.83,22.85h-2.2l-12.88-22.85v29.42h-3.5V311.2h3.59l13.84,24.74,13.93-24.74h3.55v36.14Z" fill="#d1d5db"/>
        <path d="M1397.15,311.2h2.94l14.73,36.14h-3.7l-4.43-11.3h-16.2l-4.42,11.3h-3.76Zm8.44,22-7-17.86-7,17.86Z" fill="#d1d5db"/>
        <path d="M1419.91,347.34V311.2h15a9.13,9.13,0,0,1,4.19,1,10.8,10.8,0,0,1,3.32,2.62,12.25,12.25,0,0,1,2.19,3.67,11.21,11.21,0,0,1,.78,4.09,12.37,12.37,0,0,1-1,4.89,10.89,10.89,0,0,1-2.75,3.87,9,9,0,0,1-4.16,2.08l8.68,13.95h-3.94L1433.9,334h-10.48v13.34Zm3.51-16.49H1435a5.91,5.91,0,0,0,3.64-1.17,7.78,7.78,0,0,0,2.4-3.08,9.81,9.81,0,0,0,.85-4.05,8.74,8.74,0,0,0-1-4.05,8.51,8.51,0,0,0-2.6-3,6.17,6.17,0,0,0-3.62-1.14h-11.28Z" fill="#d1d5db"/>
        <path d="M1466.81,347.59a14.93,14.93,0,0,1-6.79-1.53,16.25,16.25,0,0,1-5.24-4.14,19.73,19.73,0,0,1-3.4-5.91,19.47,19.47,0,0,1-1.2-6.74,19.22,19.22,0,0,1,1.25-6.89,18.62,18.62,0,0,1,3.52-5.86,17.24,17.24,0,0,1,5.31-4.07,14.79,14.79,0,0,1,6.6-1.5,14.64,14.64,0,0,1,6.8,1.57,16.8,16.8,0,0,1,5.21,4.23,20.11,20.11,0,0,1,3.37,5.91,19.3,19.3,0,0,1-.06,13.56,18.91,18.91,0,0,1-3.49,5.85,16.72,16.72,0,0,1-5.29,4.05A15,15,0,0,1,1466.81,347.59Zm-13.13-18.32a17.22,17.22,0,0,0,.94,5.68,15.64,15.64,0,0,0,2.7,4.83,13.16,13.16,0,0,0,4.17,3.36,12.1,12.1,0,0,0,10.79-.06,13.15,13.15,0,0,0,4.15-3.46,16.13,16.13,0,0,0,2.62-4.85,17.54,17.54,0,0,0,.89-5.5,16.72,16.72,0,0,0-1-5.65,15.65,15.65,0,0,0-2.71-4.84,13.2,13.2,0,0,0-4.18-3.35,12,12,0,0,0-10.7.05,13,13,0,0,0-4.16,3.43,16.46,16.46,0,0,0-2.64,4.86A16.75,16.75,0,0,0,1453.68,329.27Z" fill="#d1d5db"/>
        <path d="M1504.46,347.59a15,15,0,0,1-7.09-1.53,12.33,12.33,0,0,1-4.6-4.09,18.26,18.26,0,0,1-2.47-5.83,29.28,29.28,0,0,1-.77-6.72V311.2H1493v18.22a25.87,25.87,0,0,0,.57,5.5,15.39,15.39,0,0,0,1.87,4.83,9.49,9.49,0,0,0,3.5,3.39,11,11,0,0,0,5.49,1.24,10.91,10.91,0,0,0,5.61-1.3,9.55,9.55,0,0,0,3.51-3.43,15.74,15.74,0,0,0,1.82-4.81,27.31,27.31,0,0,0,.55-5.42V311.2h3.49v18.22a30,30,0,0,1-.79,6.92,16.76,16.76,0,0,1-2.58,5.8,12.76,12.76,0,0,1-4.62,4A15,15,0,0,1,1504.46,347.59Z" fill="#d1d5db"/>
        <path d="M1531.22,317.77v29.57h-3.5V311.2h2.9l23.27,30.13V311.25h3.49v36.09h-3.24Z" fill="#d1d5db"/>
        <path d="M1566.27,347.34V311.2h12a17.16,17.16,0,0,1,9.41,2.42,15.25,15.25,0,0,1,5.66,6.52,21.24,21.24,0,0,1,1.9,9.08,21,21,0,0,1-2.07,9.56,15.14,15.14,0,0,1-5.9,6.32,17.5,17.5,0,0,1-9,2.24Zm25.52-18.12a17.86,17.86,0,0,0-1.57-7.68,12.24,12.24,0,0,0-4.57-5.27,13.31,13.31,0,0,0-7.33-1.91h-8.54v29.82h8.54a13.11,13.11,0,0,0,7.38-2,12.57,12.57,0,0,0,4.54-5.38A18,18,0,0,0,1591.79,329.22Z" fill="#d1d5db"/>
        <path d="M1638.48,314.36h-12.64v33h-3.49v-33h-12.64V311.2h28.77Z" fill="#d1d5db"/>
        <path d="M1671.73,311.2v36.14h-3.44v-16.9h-21v16.9h-3.5V311.2h3.5v16.08h21V311.2Z" fill="#d1d5db"/>
        <path d="M1704.45,344.18v3.16h-23.82V311.2H1704v3.16h-19.87v13h17.32v3h-17.32v13.79Z" fill="#d1d5db"/>
        <path d="M1735.4,311.46h3.3l5,12.82,5-12.82h3.34l-6.19,15.42,6.79,16.33,12.84-32h3.79l-15,36.14h-3.09l-7.44-17.87-7.5,17.87h-3l-15-36.14H1722l12.88,32,6.74-16.33Z" fill="#d1d5db"/>
        <path d="M1787.29,347.59a14.93,14.93,0,0,1-6.79-1.53,16.25,16.25,0,0,1-5.24-4.14,19.85,19.85,0,0,1-4.6-12.65,19.22,19.22,0,0,1,1.25-6.89,18.62,18.62,0,0,1,3.52-5.86,17.24,17.24,0,0,1,5.31-4.07,14.79,14.79,0,0,1,6.6-1.5,14.64,14.64,0,0,1,6.8,1.57,16.65,16.65,0,0,1,5.2,4.23,19.92,19.92,0,0,1,3.38,5.91,19.3,19.3,0,0,1-.06,13.56,18.91,18.91,0,0,1-3.49,5.85,16.72,16.72,0,0,1-5.29,4.05A15,15,0,0,1,1787.29,347.59Zm-13.13-18.32a17.22,17.22,0,0,0,.94,5.68,15.64,15.64,0,0,0,2.7,4.83,13.16,13.16,0,0,0,4.17,3.36,12.1,12.1,0,0,0,10.79-.06,13.15,13.15,0,0,0,4.15-3.46,16.13,16.13,0,0,0,2.62-4.85,17.54,17.54,0,0,0,.89-5.5,16.72,16.72,0,0,0-1-5.65,15.65,15.65,0,0,0-2.71-4.84,13.2,13.2,0,0,0-4.18-3.35,12,12,0,0,0-10.7.05,13,13,0,0,0-4.16,3.43,16.46,16.46,0,0,0-2.64,4.86A16.75,16.75,0,0,0,1774.16,329.27Z" fill="#d1d5db"/>
        <path d="M1810.51,347.34V311.2h15a9.13,9.13,0,0,1,4.19,1,10.8,10.8,0,0,1,3.32,2.62,12.49,12.49,0,0,1,2.2,3.67,11.41,11.41,0,0,1,.77,4.09,12.37,12.37,0,0,1-1,4.89,10.89,10.89,0,0,1-2.75,3.87,8.92,8.92,0,0,1-4.16,2.08l8.69,13.95h-4L1824.49,334H1814v13.34Zm3.5-16.49h11.58a5.91,5.91,0,0,0,3.64-1.17,7.89,7.89,0,0,0,2.41-3.08,10,10,0,0,0,.84-4.05,8.74,8.74,0,0,0-1-4.05,8.51,8.51,0,0,0-2.6-3,6.17,6.17,0,0,0-3.62-1.14H1814Z" fill="#d1d5db"/>
        <path d="M1843.12,347.34V311.2h3.5v33h20.47v3.16Z" fill="#d1d5db"/>
        <path d="M1872.23,347.34V311.2h12a17.16,17.16,0,0,1,9.41,2.42,15.19,15.19,0,0,1,5.66,6.52,21.09,21.09,0,0,1,1.9,9.08,20.82,20.82,0,0,1-2.07,9.56,15.12,15.12,0,0,1-5.89,6.32,17.51,17.51,0,0,1-9,2.24Zm25.51-18.12a17.72,17.72,0,0,0-1.57-7.68,12.28,12.28,0,0,0-4.56-5.27,13.35,13.35,0,0,0-7.34-1.91h-8.54v29.82h8.54a13.09,13.09,0,0,0,7.38-2,12.52,12.52,0,0,0,4.55-5.38A18,18,0,0,0,1897.74,329.22Z" fill="#d1d5db"/>
      </g>
    </g>
  </svg>
);

export default LogoDark;

