import React, { FC, SVGProps } from 'react';

interface ThumbtackProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const Thumbtack: FC<ThumbtackProps> = ({ title = 'Pin', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M308.2 192.5L302.1 48H328C341.3 48 352 37.25 352 23.1C352 10.74 341.3 0 328 0h-272C42.74 0 32 10.74 32 23.1C32 37.25 42.74 48 56 48h31.11L76.3 192.2C28.25 218.8 0 260.9 0 307C0 331.8 20.19 352 45 352H168v136C168 501.3 178.8 512 192 512s24-10.75 24-24V352h123C363.8 352 384 331.8 384 307C384 261.1 355.9 219.1 308.2 192.5zM216 304V232c0-13.25-10.75-24-24-24S168 218.8 168 232V304H48.08C49.5 274.3 72.17 246.5 109.3 229.3l12.77-5.938L135.2 48h118.8l7.344 175.1l13.25 6.156C311.8 246.5 334.5 274.3 335.9 304H216z"></path>
  </svg>
);

export default Thumbtack;
