import React, { FC, SVGProps } from 'react';

interface VolumeXMarkProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const VolumeXMark: FC<VolumeXMarkProps> = ({ title = 'Muted', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M301.2 34.85c-4.201-1.896-8.74-2.848-13.17-2.848c-7.697 0-15.29 2.784-21.27 8.1L131.8 160H48c-26.51 0-48 21.49-48 47.1v95.1c0 26.51 21.49 47.1 48 47.1h83.84l134.9 119.9c5.984 5.312 13.58 8.094 21.26 8.094c4.438 0 8.972-.9375 13.17-2.844c11.5-5.156 18.82-16.56 18.82-29.16V64C319.1 51.41 312.7 40 301.2 34.85zM272 412.4L150.1 304H48v-95.1h102.1L272 99.64V412.4zM513.9 255.1l47.03-47.03c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L480 222.1L432.1 175c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94l47.03 47.03l-47.03 47.03c-9.375 9.375-9.375 24.56 0 33.94c9.373 9.373 24.56 9.381 33.94 0L480 289.9l47.03 47.03c9.373 9.373 24.56 9.381 33.94 0c9.375-9.375 9.375-24.56 0-33.94L513.9 255.1z"></path>
  </svg>
);

export default VolumeXMark;
