import React, { FC, SVGProps } from 'react';

interface RightFromBracketProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const RightFromBracket: FC<RightFromBracketProps> = ({ title = 'Sign out', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M168 432H96c-26.4 0-48-21.6-48-48V128c0-26.4 21.6-48 48-48h72C181.3 80 192 69.25 192 56C192 42.74 181.3 32 168 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h72C181.3 480 192 469.3 192 456C192 442.7 181.3 432 168 432zM503.9 238.6L351.5 104.4c-9.844-8.75-24-10.88-36.09-5.469C303.6 104.3 296 115.9 296 128.7v55.31H176c-22.06 0-40 17.94-40 40v64c0 22.06 17.94 40 40 40h120v55.31c0 12.75 7.625 24.41 19.41 29.72c4.438 2 9.156 2.969 13.84 2.969c8.062 0 16.03-2.906 22.25-8.438l152.4-133.1C509.1 269.9 512 263.4 512 256.5S509.1 243.1 503.9 238.6zM344 350V279.1h-160v-48h160V161.1l107.8 94.54L344 350z"></path>
  </svg>
);

export default RightFromBracket;
