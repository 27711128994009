import React, { FC, SVGProps } from 'react';

interface LogoProps extends SVGProps<SVGSVGElement> {}

const Logo: FC<LogoProps> = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1914.89 482.71"
    role="img"
    aria-labelledby="logo-title"
    {...rest}
  >
    <title id="logo-title">babel chat - Meet and chat with people from around the world.</title>
    <g>
      <g>
        <path d="M44.36,371,93.17,245.49a39.57,39.57,0,0,1,50.31-22.87c47.33,17,132.91,42.57,204.09,35.15l17.26,83.46a39.55,39.55,0,0,1-35.17,47.42c-48.22,4.35-125.88,9.2-180,1.91a39,39,0,0,0-27.47,6.83L0,482.71Z" fill="#10b981"/>
        <path d="M326.83,163.06l12,71.33S240.7,251.06,131.6,196.1l13.94-65.85S225.11,177.83,326.83,163.06Z" fill="#10b981"/>
        <path d="M302.76,150.38l-6.53-63.85h0c-42.81,4.35-103.76-20.32-103.76-20.32l-14.23,50.61S215.59,148.57,302.76,150.38Z" fill="#10b981"/>
        <circle cx="253.52" cy="31.38" r="31.38" fill="#10b981"/>
      </g>
      <rect x="133.4" y="276.73" width="25.25" height="33.74" rx="5.71" fill="#f3f4f6"/>
      <rect x="204.73" y="301.58" width="25.25" height="33.74" rx="5.71" fill="#f3f4f6"/>
      <rect x="276.07" y="322.98" width="25.25" height="33.74" rx="5.71" fill="#f3f4f6"/>
    </g>
    <g>
      <g>
        <path d="M599.7,227.54a43,43,0,0,1-6.82,23.91,47.5,47.5,0,0,1-18.36,16.45,54,54,0,0,1-25.16,5.94H464.6V94.2h86.52q12.68,0,21.89,6.71a44,44,0,0,1,14.18,17.2A51,51,0,0,1,592.12,140a47.51,47.51,0,0,1-6.82,24.79,42.07,42.07,0,0,1-19,17,44.94,44.94,0,0,1,24.42,17Q599.72,211.09,599.7,227.54ZM482.31,109.63v66.3h61.75a27.08,27.08,0,0,0,15.67-4.68,34.75,34.75,0,0,0,14.93-28.47,36,36,0,0,0-3.8-16.57,31.88,31.88,0,0,0-10.36-12,25.67,25.67,0,0,0-14.93-4.56ZM582,224.51a35.87,35.87,0,0,0-4-16.83,34.31,34.31,0,0,0-11-12.52,27.05,27.05,0,0,0-15.81-4.81H482.31v68.06h67.05a29.3,29.3,0,0,0,16.44-4.81,36.23,36.23,0,0,0,11.77-12.52A32.69,32.69,0,0,0,582,224.51Z" fill="#6b7280"/>
        <path d="M685,94.2h14.93l74.64,179.64H755.81l-22.45-56.17H651.24l-22.45,56.17h-19Zm42.85,109.56L692.3,115l-35.48,88.81Z" fill="#6b7280"/>
        <path d="M935.45,227.54a43,43,0,0,1-6.82,23.91,47.5,47.5,0,0,1-18.36,16.45,54,54,0,0,1-25.16,5.94H800.35V94.2h86.52q12.68,0,21.89,6.71a44.12,44.12,0,0,1,14.18,17.2A51.16,51.16,0,0,1,927.86,140,47.42,47.42,0,0,1,921,164.79a42,42,0,0,1-19,17,44.87,44.87,0,0,1,24.41,17Q935.48,211.09,935.45,227.54ZM818.05,109.63v66.3H879.8a27.11,27.11,0,0,0,15.68-4.68,34.77,34.77,0,0,0,14.92-28.47,36,36,0,0,0-3.79-16.57,31.91,31.91,0,0,0-10.37-12,25.63,25.63,0,0,0-14.93-4.56Zm99.69,114.88a35.75,35.75,0,0,0-4-16.83,34.38,34.38,0,0,0-11-12.52,27.05,27.05,0,0,0-15.81-4.81H818.05v68.06h67.06a29.32,29.32,0,0,0,16.44-4.81,36.32,36.32,0,0,0,11.77-12.52A32.68,32.68,0,0,0,917.74,224.51Z" fill="#6b7280"/>
        <path d="M1089.55,258.16v15.68H968.85V94.2h118.42v15.69H986.56v64.77h87.79v14.93H986.56v68.57Z" fill="#6b7280"/>
        <path d="M1122.93,273.84V94.2h17.73v164h103.73v15.68Z" fill="#6b7280"/>
        <path d="M1256.79,182.5a93.56,93.56,0,0,1,22.9-61.1A83.26,83.26,0,0,1,1307,100.65q16.05-7.71,36.54-7.71,24.56,0,42.14,11t26.18,28.21l-22.27,14.93c-3.21-7.25-7.36-13-12.51-17.2a45.62,45.62,0,0,0-16.83-8.86,66,66,0,0,0-17.7-2.53q-13.93,0-24.55,5.69a55,55,0,0,0-17.85,15.06,65.3,65.3,0,0,0-10.75,21,79.3,79.3,0,0,0-3.54,23.27,75.19,75.19,0,0,0,4.18,25.06,67.12,67.12,0,0,0,11.9,21.12,56.8,56.8,0,0,0,18.2,14.55,50.6,50.6,0,0,0,23.15,5.31,59.09,59.09,0,0,0,18.23-3,52.59,52.59,0,0,0,17.32-9.62,45.35,45.35,0,0,0,12.53-16.7l23.54,13.16a54.4,54.4,0,0,1-17.08,22.77,84.1,84.1,0,0,1-26.44,14.17,93.25,93.25,0,0,1-29.12,4.81,74.31,74.31,0,0,1-34.14-8,89.11,89.11,0,0,1-27.2-21.26A99.12,99.12,0,0,1,1263.11,216,93.28,93.28,0,0,1,1256.79,182.5Z" fill="#10b981"/>
        <path d="M1588.74,94.2V273.84H1560.4V194.65h-90.59v79.19h-28.59V94.2h28.59v75.15h90.59V94.2Z" fill="#10b981"/>
        <path d="M1681.09,94.2h26.83l69.83,179.64H1747.9L1730.32,225H1658.5l-17.63,48.83H1611ZM1722.78,204l-28.28-78.69L1666.07,204Z" fill="#10b981"/>
        <path d="M1914.89,119.25h-59.72V273.84h-28.59V119.25h-60v-25h148.27Z" fill="#10b981"/>
      </g>
      <g>
        <path d="M496.61,347.34V317.92l-13.07,22.85H481.3l-13.13-22.85v29.42H464.6V311.2h3.67l14.1,24.74,14.2-24.74h3.61v36.14Z" fill="#6b7280"/>
        <path d="M533.52,344.18v3.16H509.24V311.2h23.82v3.16H512.81v13h17.65v3H512.81v13.79Z" fill="#6b7280"/>
        <path d="M564.5,344.18v3.16H540.22V311.2h23.83v3.16H543.79v13h17.65v3H543.79v13.79Z" fill="#6b7280"/>
        <path d="M596.88,314.36H584v33h-3.56v-33H567.56V311.2h29.32Z" fill="#6b7280"/>
        <path d="M623.44,311.2h3l15,36.14h-3.77L633.17,336H616.66l-4.51,11.3h-3.83Zm8.61,22-7.14-17.86-7.13,17.86Z" fill="#6b7280"/>
        <path d="M650.21,317.77v29.57h-3.57V311.2h3l23.71,30.13V311.25h3.56v36.09h-3.3Z" fill="#6b7280"/>
        <path d="M685.94,347.34V311.2h12.27a17.73,17.73,0,0,1,9.59,2.42,15.3,15.3,0,0,1,5.77,6.52,20.82,20.82,0,0,1,1.94,9.08,20.57,20.57,0,0,1-2.11,9.56,15.27,15.27,0,0,1-6,6.32,18.12,18.12,0,0,1-9.18,2.24Zm26-18.12a17.44,17.44,0,0,0-1.6-7.68,12.35,12.35,0,0,0-4.65-5.27,13.79,13.79,0,0,0-7.48-1.91h-8.7v29.82h8.7a13.54,13.54,0,0,0,7.52-2,12.58,12.58,0,0,0,4.64-5.38A17.75,17.75,0,0,0,711.94,329.22Z" fill="#6b7280"/>
        <path d="M732.91,329a19.07,19.07,0,0,1,1.14-6.48,19.28,19.28,0,0,1,3.34-5.81,15.86,15.86,0,0,1,5.4-4.17,16.52,16.52,0,0,1,7.27-1.55,14.89,14.89,0,0,1,8.27,2.21,13.45,13.45,0,0,1,5.06,5.68l-2.8,1.73a10.58,10.58,0,0,0-3-3.79,11.52,11.52,0,0,0-3.79-2,13.91,13.91,0,0,0-4-.61,12.31,12.31,0,0,0-5.75,1.3,12.75,12.75,0,0,0-4.2,3.46,15.33,15.33,0,0,0-2.57,4.84,17.35,17.35,0,0,0-.86,5.42,16.38,16.38,0,0,0,1,5.75,15.53,15.53,0,0,0,2.85,4.86,14,14,0,0,0,4.3,3.36,11.75,11.75,0,0,0,5.37,1.24,13.3,13.3,0,0,0,8.17-2.89,10.79,10.79,0,0,0,3-3.83l3,1.53a11.39,11.39,0,0,1-3.45,4.58,16.59,16.59,0,0,1-5.22,2.85,17.45,17.45,0,0,1-5.62,1A14.59,14.59,0,0,1,743,346a17.43,17.43,0,0,1-5.37-4.28,19.52,19.52,0,0,1-3.49-6A19.24,19.24,0,0,1,732.91,329Z" fill="#6b7280"/>
        <path d="M798.72,311.2v36.14H795.2v-16.9H773.78v16.9h-3.56V311.2h3.56v16.08H795.2V311.2Z" fill="#6b7280"/>
        <path d="M819,311.2h3l15,36.14h-3.77L828.7,336H812.19l-4.51,11.3h-3.83Zm8.61,22-7.14-17.86-7.13,17.86Z" fill="#6b7280"/>
        <path d="M864.36,314.36H851.48v33h-3.56v-33H835.05V311.2h29.31Z" fill="#6b7280"/>
        <path d="M893.17,311.46h3.36l5.08,12.82,5.15-12.82h3.41l-6.32,15.42,6.92,16.33,13.09-32h3.86l-15.31,36.14h-3.15l-7.59-17.87L894,347.34h-3.1L875.66,311.2h3.82l13.13,32,6.87-16.33Z" fill="#6b7280"/>
        <path d="M932.81,347.34V311.2h3.57v36.14Z" fill="#6b7280"/>
        <path d="M971.09,314.36H958.21v33h-3.56v-33H941.77V311.2h29.32Z" fill="#6b7280"/>
        <path d="M1005,311.2v36.14h-3.51v-16.9H980.05v16.9h-3.57V311.2h3.57v16.08h21.42V311.2Z" fill="#6b7280"/>
        <path d="M1027,347.34V311.2h15a9.63,9.63,0,0,1,4.3,1,10.69,10.69,0,0,1,3.38,2.62,12.83,12.83,0,0,1,2.24,3.67,10.66,10.66,0,0,1,.82,4.09,12.29,12.29,0,0,1-1.3,5.57,10.89,10.89,0,0,1-3.64,4.25,9.34,9.34,0,0,1-5.49,1.63h-11.7v13.34Zm3.57-16.49h11.55a6.12,6.12,0,0,0,3.71-1.17,7.89,7.89,0,0,0,2.45-3.06,9.71,9.71,0,0,0,.86-4.07,8.53,8.53,0,0,0-1-4.12,8.13,8.13,0,0,0-2.67-3,6.48,6.48,0,0,0-3.64-1.09h-11.24Z" fill="#6b7280"/>
        <path d="M1082.94,344.18v3.16h-24.27V311.2h23.82v3.16h-20.25v13h17.65v3h-17.65v13.79Z" fill="#6b7280"/>
        <path d="M1103.51,347.59a15.45,15.45,0,0,1-6.92-1.53,16.53,16.53,0,0,1-5.34-4.14,19.47,19.47,0,0,1-3.46-5.91,19.2,19.2,0,0,1,0-13.63,18.46,18.46,0,0,1,3.6-5.86,17.41,17.41,0,0,1,5.41-4.07,15.31,15.31,0,0,1,6.72-1.5,15.15,15.15,0,0,1,6.93,1.57,17,17,0,0,1,5.31,4.23,20.09,20.09,0,0,1,3.44,5.91,18.69,18.69,0,0,1,1.22,6.66,19.19,19.19,0,0,1-4.84,12.75,17,17,0,0,1-5.39,4.05A15.5,15.5,0,0,1,1103.51,347.59Zm-13.38-18.32a17,17,0,0,0,1,5.68,15.56,15.56,0,0,0,2.75,4.83,13.36,13.36,0,0,0,4.25,3.36,12.55,12.55,0,0,0,11-.06,13.41,13.41,0,0,0,4.22-3.46,15.62,15.62,0,0,0,2.67-4.85,17,17,0,0,0,.91-5.5,16.48,16.48,0,0,0-1-5.65,15.8,15.8,0,0,0-2.77-4.84,13.28,13.28,0,0,0-4.26-3.35,12.48,12.48,0,0,0-10.91.05,13.25,13.25,0,0,0-4.23,3.43,16.17,16.17,0,0,0-2.7,4.86A16.51,16.51,0,0,0,1090.13,329.27Z" fill="#6b7280"/>
        <path d="M1127.17,347.34V311.2h15a9.59,9.59,0,0,1,4.29,1,10.73,10.73,0,0,1,3.39,2.62,13.06,13.06,0,0,1,2.24,3.67,10.84,10.84,0,0,1,.81,4.09,12.41,12.41,0,0,1-1.29,5.57,11.05,11.05,0,0,1-3.64,4.25,9.39,9.39,0,0,1-5.5,1.63h-11.7v13.34Zm3.57-16.49h11.55a6.12,6.12,0,0,0,3.71-1.17,7.8,7.8,0,0,0,2.45-3.06,9.71,9.71,0,0,0,.86-4.07,8.52,8.52,0,0,0-1-4.12,8.31,8.31,0,0,0-2.67-3,6.48,6.48,0,0,0-3.64-1.09h-11.25Z" fill="#6b7280"/>
        <path d="M1158.82,347.34V311.2h3.57v33h20.86v3.16Z" fill="#6b7280"/>
        <path d="M1212.77,344.18v3.16h-24.28V311.2h23.82v3.16h-20.25v13h17.65v3h-17.65v13.79Z" fill="#6b7280"/>
        <path d="M1232.46,347.34V311.2h23.72v3.16H1236v13.28h17v2.95h-17v16.75Z" fill="#6b7280"/>
        <path d="M1262.44,347.34V311.2h15.28a9.47,9.47,0,0,1,4.27,1,11,11,0,0,1,3.38,2.62,12.4,12.4,0,0,1,2.24,3.67,11.22,11.22,0,0,1,.79,4.09,12,12,0,0,1-1,4.89,10.89,10.89,0,0,1-2.8,3.87,9.18,9.18,0,0,1-4.25,2.08l8.86,13.95h-4L1276.7,334H1266v13.34Zm3.57-16.49h11.81a6.12,6.12,0,0,0,3.71-1.17,7.8,7.8,0,0,0,2.45-3.08,9.82,9.82,0,0,0,.86-4.05,8.53,8.53,0,0,0-3.64-7.05,6.33,6.33,0,0,0-3.68-1.14H1266Z" fill="#6b7280"/>
        <path d="M1310.23,347.59a15.45,15.45,0,0,1-6.92-1.53,16.42,16.42,0,0,1-5.34-4.14,19.5,19.5,0,0,1-3.47-5.91,19.26,19.26,0,0,1-1.22-6.74,19,19,0,0,1,1.27-6.89,18.62,18.62,0,0,1,3.59-5.86,17.45,17.45,0,0,1,5.42-4.07,15.94,15.94,0,0,1,13.65.07,16.92,16.92,0,0,1,5.3,4.23,19.9,19.9,0,0,1,3.45,5.91,19.13,19.13,0,0,1-3.62,19.41,17.12,17.12,0,0,1-5.39,4.05A15.57,15.57,0,0,1,1310.23,347.59Zm-13.38-18.32a17,17,0,0,0,1,5.68,15.78,15.78,0,0,0,2.76,4.83,13.43,13.43,0,0,0,4.24,3.36,12.57,12.57,0,0,0,11-.06,13.41,13.41,0,0,0,4.22-3.46,15.83,15.83,0,0,0,2.67-4.85,17,17,0,0,0,.91-5.5,16.25,16.25,0,0,0-1-5.65,15.58,15.58,0,0,0-2.76-4.84,13.49,13.49,0,0,0-4.26-3.35,12.48,12.48,0,0,0-10.91.05,13,13,0,0,0-4.23,3.43,16.17,16.17,0,0,0-2.7,4.86A16.51,16.51,0,0,0,1296.85,329.27Z" fill="#6b7280"/>
        <path d="M1365.9,347.34V317.92l-13.08,22.85h-2.24l-13.12-22.85v29.42h-3.57V311.2h3.66l14.11,24.74,14.19-24.74h3.62v36.14Z" fill="#6b7280"/>
        <path d="M1401.12,311.2h3l15,36.14h-3.77l-4.52-11.3h-16.51l-4.51,11.3H1386Zm8.61,22-7.14-17.86-7.13,17.86Z" fill="#6b7280"/>
        <path d="M1424.33,347.34V311.2h15.27a9.47,9.47,0,0,1,4.27,1,11,11,0,0,1,3.38,2.62,12.2,12.2,0,0,1,2.24,3.67,11,11,0,0,1,.79,4.09,12,12,0,0,1-1,4.89,10.89,10.89,0,0,1-2.8,3.87,9.19,9.19,0,0,1-4.24,2.08l8.85,13.95h-4l-8.5-13.34H1427.9v13.34Zm3.57-16.49h11.8a6.1,6.1,0,0,0,3.71-1.17,7.8,7.8,0,0,0,2.45-3.08,9.82,9.82,0,0,0,.87-4.05,8.52,8.52,0,0,0-1-4.05,8.61,8.61,0,0,0-2.64-3,6.39,6.39,0,0,0-3.69-1.14h-11.5Z" fill="#6b7280"/>
        <path d="M1472.12,347.59a15.38,15.38,0,0,1-6.91-1.53,16.46,16.46,0,0,1-5.35-4.14,19.47,19.47,0,0,1-3.46-5.91,19.2,19.2,0,0,1,0-13.63,18.83,18.83,0,0,1,3.6-5.86,17.53,17.53,0,0,1,5.41-4.07,15.31,15.31,0,0,1,6.72-1.5,15.15,15.15,0,0,1,6.93,1.57,16.85,16.85,0,0,1,5.31,4.23,20.09,20.09,0,0,1,3.44,5.91,18.7,18.7,0,0,1,1.23,6.66,19.27,19.27,0,0,1-4.85,12.75,16.89,16.89,0,0,1-5.39,4.05A15.5,15.5,0,0,1,1472.12,347.59Zm-13.38-18.32a17,17,0,0,0,1,5.68,15.56,15.56,0,0,0,2.75,4.83,13.36,13.36,0,0,0,4.25,3.36,12.55,12.55,0,0,0,11-.06,13.19,13.19,0,0,0,4.22-3.46,15.85,15.85,0,0,0,2.68-4.85,17.28,17.28,0,0,0,.91-5.5,16.49,16.49,0,0,0-1-5.65,15.6,15.6,0,0,0-2.77-4.84,13.28,13.28,0,0,0-4.26-3.35,12.46,12.46,0,0,0-10.9.05,13.18,13.18,0,0,0-4.24,3.43,16.14,16.14,0,0,0-2.69,4.86A16.52,16.52,0,0,0,1458.74,329.27Z" fill="#6b7280"/>
        <path d="M1510.5,347.59a15.55,15.55,0,0,1-7.23-1.53,12.46,12.46,0,0,1-4.69-4.09,18.12,18.12,0,0,1-2.52-5.83,28.74,28.74,0,0,1-.78-6.72V311.2h3.51v18.22a25.25,25.25,0,0,0,.59,5.5,15,15,0,0,0,1.9,4.83,9.68,9.68,0,0,0,3.57,3.39,11.4,11.4,0,0,0,5.6,1.24,11.27,11.27,0,0,0,5.71-1.3,9.57,9.57,0,0,0,3.57-3.43,15.06,15.06,0,0,0,1.86-4.81,26.59,26.59,0,0,0,.56-5.42V311.2h3.56v18.22a29,29,0,0,1-.81,6.92,16.63,16.63,0,0,1-2.62,5.8,12.93,12.93,0,0,1-4.72,4A15.45,15.45,0,0,1,1510.5,347.59Z" fill="#6b7280"/>
        <path d="M1537.77,317.77v29.57h-3.57V311.2h3l23.72,30.13V311.25h3.56v36.09h-3.3Z" fill="#6b7280"/>
        <path d="M1573.49,347.34V311.2h12.28a17.75,17.75,0,0,1,9.59,2.42,15.3,15.3,0,0,1,5.77,6.52,20.82,20.82,0,0,1,1.94,9.08,20.57,20.57,0,0,1-2.11,9.56,15.33,15.33,0,0,1-6,6.32,18.12,18.12,0,0,1-9.18,2.24Zm26-18.12a17.58,17.58,0,0,0-1.6-7.68,12.37,12.37,0,0,0-4.66-5.27,13.76,13.76,0,0,0-7.47-1.91h-8.71v29.82h8.71a13.54,13.54,0,0,0,7.52-2,12.63,12.63,0,0,0,4.63-5.38A17.75,17.75,0,0,0,1599.5,329.22Z" fill="#6b7280"/>
        <path d="M1647.09,314.36h-12.88v33h-3.56v-33h-12.88V311.2h29.32Z" fill="#6b7280"/>
        <path d="M1681,311.2v36.14h-3.52v-16.9h-21.41v16.9h-3.57V311.2h3.57v16.08h21.41V311.2Z" fill="#6b7280"/>
        <path d="M1714.32,344.18v3.16H1690V311.2h23.82v3.16h-20.25v13h17.65v3h-17.65v13.79Z" fill="#6b7280"/>
        <path d="M1745.86,311.46h3.36l5.09,12.82,5.15-12.82h3.4l-6.31,15.42,6.92,16.33,13.09-32h3.86l-15.32,36.14H1762l-7.58-17.87-7.64,17.87h-3.11l-15.27-36.14h3.83l13.12,32,6.88-16.33Z" fill="#6b7280"/>
        <path d="M1798.75,347.59a15.45,15.45,0,0,1-6.92-1.53,16.42,16.42,0,0,1-5.34-4.14A19.5,19.5,0,0,1,1783,336a19.26,19.26,0,0,1-1.22-6.74,19,19,0,0,1,1.27-6.89,18.62,18.62,0,0,1,3.59-5.86,17.45,17.45,0,0,1,5.42-4.07,15.94,15.94,0,0,1,13.65.07,16.92,16.92,0,0,1,5.3,4.23,19.87,19.87,0,0,1,3.44,5.91,18.7,18.7,0,0,1,1.23,6.66,19.19,19.19,0,0,1-4.84,12.75,17.12,17.12,0,0,1-5.39,4.05A15.57,15.57,0,0,1,1798.75,347.59Zm-13.38-18.32a17,17,0,0,0,1,5.68,15.78,15.78,0,0,0,2.76,4.83,13.43,13.43,0,0,0,4.24,3.36,12.57,12.57,0,0,0,11-.06,13.41,13.41,0,0,0,4.22-3.46,16,16,0,0,0,2.67-4.85,17,17,0,0,0,.91-5.5,16.49,16.49,0,0,0-1-5.65,15.58,15.58,0,0,0-2.76-4.84,13.49,13.49,0,0,0-4.26-3.35,12.48,12.48,0,0,0-10.91.05,13,13,0,0,0-4.23,3.43,16.17,16.17,0,0,0-2.7,4.86A16.51,16.51,0,0,0,1785.37,329.27Z" fill="#6b7280"/>
        <path d="M1822.41,347.34V311.2h15.27a9.44,9.44,0,0,1,4.27,1,10.92,10.92,0,0,1,3.38,2.62,12.2,12.2,0,0,1,2.24,3.67,11,11,0,0,1,.8,4.09,12.05,12.05,0,0,1-1,4.89,10.77,10.77,0,0,1-2.8,3.87,9.19,9.19,0,0,1-4.24,2.08l8.85,13.95h-4l-8.5-13.34H1826v13.34Zm3.57-16.49h11.8a6.1,6.1,0,0,0,3.71-1.17,7.8,7.8,0,0,0,2.45-3.08,9.67,9.67,0,0,0,.87-4.05,8.63,8.63,0,0,0-1-4.05,8.74,8.74,0,0,0-2.65-3,6.39,6.39,0,0,0-3.69-1.14H1826Z" fill="#6b7280"/>
        <path d="M1855.65,347.34V311.2h3.57v33h20.85v3.16Z" fill="#6b7280"/>
        <path d="M1885.31,347.34V311.2h12.28a17.75,17.75,0,0,1,9.59,2.42,15.3,15.3,0,0,1,5.77,6.52,20.82,20.82,0,0,1,1.94,9.08,20.57,20.57,0,0,1-2.11,9.56,15.33,15.33,0,0,1-6,6.32,18.12,18.12,0,0,1-9.18,2.24Zm26-18.12a17.58,17.58,0,0,0-1.6-7.68,12.37,12.37,0,0,0-4.66-5.27,13.76,13.76,0,0,0-7.47-1.91h-8.71v29.82h8.71a13.54,13.54,0,0,0,7.52-2,12.63,12.63,0,0,0,4.63-5.38A17.75,17.75,0,0,0,1911.32,329.22Z" fill="#6b7280"/>
      </g>
    </g>
  </svg>
);

export default Logo;
