import React, { FC, SVGProps } from 'react';

interface FilterProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const Filter: FC<FilterProps> = ({ title = 'Filter', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M507.7 58.71C500 42.23 483.8 32 465.3 32H46.65C28.21 32 11.97 42.23 4.283 58.71C-3.195 74.73-.7578 93.16 10.64 106.8l165.4 203.1v67.71c0 12.55 6.178 24.3 16.52 31.45l82.32 63.55C281.1 477.6 289.6 480 297.4 480c21.28 0 38.59-17.19 38.59-38.33V309.9l165.5-203.3C512.8 93.16 515.2 74.73 507.7 58.71zM295.2 283.1c-4.652 5.713-7.191 12.86-7.191 20.23V422.1l-64-49.39V304.2c0-7.371-2.539-14.52-7.191-20.23L50.77 80.04h410.4L295.2 283.1z"></path>
  </svg>
);

export default Filter;
