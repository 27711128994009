import { Country, CountryOption } from '../types/country';

export const COUNTRIES: CountryOption[] = [
  { value: Country.AF, label: 'Afghanistan' },
  { value: Country.AX, label: 'Åland Islands' },
  { value: Country.AL, label: 'Albania' },
  { value: Country.DZ, label: 'Algeria' },
  { value: Country.AS, label: 'American Samoa' },
  { value: Country.AD, label: 'Andorra' },
  { value: Country.AO, label: 'Angola' },
  { value: Country.AI, label: 'Anguilla' },
  { value: Country.AQ, label: 'Antarctica' },
  { value: Country.AG, label: 'Antigua and Barbuda' },
  { value: Country.AR, label: 'Argentina' },
  { value: Country.AM, label: 'Armenia' },
  { value: Country.AW, label: 'Aruba' },
  { value: Country.AU, label: 'Australia' },
  { value: Country.AT, label: 'Austria' },
  { value: Country.AZ, label: 'Azerbaijan' },
  { value: Country.BS, label: 'Bahamas' },
  { value: Country.BH, label: 'Bahrain' },
  { value: Country.BD, label: 'Bangladesh' },
  { value: Country.BB, label: 'Barbados' },
  { value: Country.BY, label: 'Belarus' },
  { value: Country.BE, label: 'Belgium' },
  { value: Country.BZ, label: 'Belize' },
  { value: Country.BJ, label: 'Benin' },
  { value: Country.BM, label: 'Bermuda' },
  { value: Country.BT, label: 'Bhutan' },
  { value: Country.BO, label: 'Bolivia' },
  { value: Country.BA, label: 'Bosnia and Herzegovina' },
  { value: Country.BW, label: 'Botswana' },
  { value: Country.BV, label: 'Bouvet Island' },
  { value: Country.BR, label: 'Brazil' },
  { value: Country.IO, label: 'British Indian Ocean Territory' },
  { value: Country.BN, label: 'Brunei Darussalam' },
  { value: Country.BG, label: 'Bulgaria' },
  { value: Country.BF, label: 'Burkina Faso' },
  { value: Country.BI, label: 'Burundi' },
  { value: Country.KH, label: 'Cambodia' },
  { value: Country.CM, label: 'Cameroon' },
  { value: Country.CA, label: 'Canada', prioritized: true },
  { value: Country.CV, label: 'Cape Verde' },
  { value: Country.KY, label: 'Cayman Islands' },
  { value: Country.CF, label: 'Central African Republic' },
  { value: Country.TD, label: 'Chad' },
  { value: Country.CL, label: 'Chile' },
  { value: Country.CN, label: 'China' },
  { value: Country.CX, label: 'Christmas Island' },
  { value: Country.CC, label: 'Cocos (Keeling) Islands' },
  { value: Country.CO, label: 'Colombia' },
  { value: Country.KM, label: 'Comoros' },
  { value: Country.CG, label: 'Congo' },
  { value: Country.CD, label: 'Congo, Democratic Republic' },
  { value: Country.CK, label: 'Cook Islands' },
  { value: Country.CR, label: 'Costa Rica' },
  { value: Country.CI, label: "Cote D'Ivoire" },
  { value: Country.HR, label: 'Croatia' },
  { value: Country.CU, label: 'Cuba' },
  { value: Country.CY, label: 'Cyprus' },
  { value: Country.CZ, label: 'Czech Republic' },
  { value: Country.DK, label: 'Denmark' },
  { value: Country.DJ, label: 'Djibouti' },
  { value: Country.DM, label: 'Dominica' },
  { value: Country.DO, label: 'Dominican Republic' },
  { value: Country.EC, label: 'Ecuador' },
  { value: Country.EG, label: 'Egypt' },
  { value: Country.SV, label: 'El Salvador' },
  { value: Country.GQ, label: 'Equatorial Guinea' },
  { value: Country.ER, label: 'Eritrea' },
  { value: Country.EE, label: 'Estonia' },
  { value: Country.ET, label: 'Ethiopia' },
  { value: Country.FK, label: 'Falkland Islands (Malvinas)' },
  { value: Country.FO, label: 'Faroe Islands' },
  { value: Country.FJ, label: 'Fiji' },
  { value: Country.FI, label: 'Finland' },
  { value: Country.FR, label: 'France' },
  { value: Country.GF, label: 'French Guiana' },
  { value: Country.PF, label: 'French Polynesia' },
  { value: Country.TF, label: 'French Southern Territories' },
  { value: Country.GA, label: 'Gabon' },
  { value: Country.GM, label: 'Gambia' },
  { value: Country.GE, label: 'Georgia' },
  { value: Country.DE, label: 'Germany' },
  { value: Country.GH, label: 'Ghana' },
  { value: Country.GI, label: 'Gibraltar' },
  { value: Country.GR, label: 'Greece' },
  { value: Country.GL, label: 'Greenland' },
  { value: Country.GD, label: 'Grenada' },
  { value: Country.GP, label: 'Guadeloupe' },
  { value: Country.GU, label: 'Guam' },
  { value: Country.GT, label: 'Guatemala' },
  { value: Country.GG, label: 'Guernsey' },
  { value: Country.GN, label: 'Guinea' },
  { value: Country.GW, label: 'Guinea-Bissau' },
  { value: Country.GY, label: 'Guyana' },
  { value: Country.HT, label: 'Haiti' },
  { value: Country.HM, label: 'Heard Island and Mcdonald Islands' },
  { value: Country.VA, label: 'Holy See (Vatican City State)' },
  { value: Country.HN, label: 'Honduras' },
  { value: Country.HK, label: 'Hong Kong' },
  { value: Country.HU, label: 'Hungary' },
  { value: Country.IS, label: 'Iceland' },
  { value: Country.IN, label: 'India' },
  { value: Country.ID, label: 'Indonesia' },
  { value: Country.IR, label: 'Iran' },
  { value: Country.IQ, label: 'Iraq' },
  { value: Country.IE, label: 'Ireland' },
  { value: Country.IM, label: 'Isle of Man' },
  { value: Country.IL, label: 'Israel' },
  { value: Country.IT, label: 'Italy' },
  { value: Country.JM, label: 'Jamaica' },
  { value: Country.JP, label: 'Japan' },
  { value: Country.JE, label: 'Jersey' },
  { value: Country.JO, label: 'Jordan' },
  { value: Country.KZ, label: 'Kazakhstan' },
  { value: Country.KE, label: 'Kenya' },
  { value: Country.KI, label: 'Kiribati' },
  { value: Country.KP, label: 'Korea, DPRK' },
  { value: Country.KR, label: 'Korea, Republic of' },
  { value: Country.KW, label: 'Kuwait' },
  { value: Country.KG, label: 'Kyrgyzstan' },
  { value: Country.LA, label: "Lao People's Democratic Republic" },
  { value: Country.LV, label: 'Latvia' },
  { value: Country.LB, label: 'Lebanon' },
  { value: Country.LS, label: 'Lesotho' },
  { value: Country.LR, label: 'Liberia' },
  { value: Country.LY, label: 'Libyan Arab Jamahiriya' },
  { value: Country.LI, label: 'Liechtenstein' },
  { value: Country.LT, label: 'Lithuania' },
  { value: Country.LU, label: 'Luxembourg' },
  { value: Country.MO, label: 'Macao' },
  { value: Country.MK, label: 'Macedonia' },
  { value: Country.MG, label: 'Madagascar' },
  { value: Country.MW, label: 'Malawi' },
  { value: Country.MY, label: 'Malaysia' },
  { value: Country.MV, label: 'Maldives' },
  { value: Country.ML, label: 'Mali' },
  { value: Country.MT, label: 'Malta' },
  { value: Country.MH, label: 'Marshall Islands' },
  { value: Country.MQ, label: 'Martinique' },
  { value: Country.MR, label: 'Mauritania' },
  { value: Country.MU, label: 'Mauritius' },
  { value: Country.YT, label: 'Mayotte' },
  { value: Country.MX, label: 'Mexico' },
  { value: Country.FM, label: 'Micronesia' },
  { value: Country.MD, label: 'Moldova' },
  { value: Country.MC, label: 'Monaco' },
  { value: Country.MN, label: 'Mongolia' },
  { value: Country.MS, label: 'Montserrat' },
  { value: Country.MA, label: 'Morocco' },
  { value: Country.MZ, label: 'Mozambique' },
  { value: Country.MM, label: 'Myanmar' },
  { value: Country.NA, label: 'Namibia' },
  { value: Country.NR, label: 'Nauru' },
  { value: Country.NP, label: 'Nepal' },
  { value: Country.NL, label: 'Netherlands' },
  { value: Country.AN, label: 'Netherlands Antilles' },
  { value: Country.NC, label: 'New Caledonia' },
  { value: Country.NZ, label: 'New Zealand' },
  { value: Country.NI, label: 'Nicaragua' },
  { value: Country.NE, label: 'Niger' },
  { value: Country.NG, label: 'Nigeria' },
  { value: Country.NU, label: 'Niue' },
  { value: Country.NF, label: 'Norfolk Island' },
  { value: Country.MP, label: 'Northern Mariana Islands' },
  { value: Country.NO, label: 'Norway' },
  { value: Country.OM, label: 'Oman' },
  { value: Country.PK, label: 'Pakistan' },
  { value: Country.PW, label: 'Palau' },
  { value: Country.PS, label: 'Palestinian Territory' },
  { value: Country.PA, label: 'Panama' },
  { value: Country.PG, label: 'Papua New Guinea' },
  { value: Country.PY, label: 'Paraguay' },
  { value: Country.PE, label: 'Peru' },
  { value: Country.PH, label: 'Philippines' },
  { value: Country.PN, label: 'Pitcairn' },
  { value: Country.PL, label: 'Poland' },
  { value: Country.PT, label: 'Portugal' },
  { value: Country.PR, label: 'Puerto Rico' },
  { value: Country.QA, label: 'Qatar' },
  { value: Country.RE, label: 'Reunion' },
  { value: Country.RO, label: 'Romania' },
  { value: Country.RU, label: 'Russian Federation' },
  { value: Country.RW, label: 'Rwanda' },
  { value: Country.SH, label: 'Saint Helena' },
  { value: Country.KN, label: 'Saint Kitts and Nevis' },
  { value: Country.LC, label: 'Saint Lucia' },
  { value: Country.PM, label: 'Saint Pierre and Miquelon' },
  { value: Country.VC, label: 'Saint Vincent and the Grenadines' },
  { value: Country.WS, label: 'Samoa' },
  { value: Country.SM, label: 'San Marino' },
  { value: Country.ST, label: 'Sao Tome and Principe' },
  { value: Country.SA, label: 'Saudi Arabia' },
  { value: Country.SN, label: 'Senegal' },
  { value: Country.CS, label: 'Serbia and Montenegro' },
  { value: Country.SC, label: 'Seychelles' },
  { value: Country.SL, label: 'Sierra Leone' },
  { value: Country.SG, label: 'Singapore' },
  { value: Country.SK, label: 'Slovakia' },
  { value: Country.SI, label: 'Slovenia' },
  { value: Country.SB, label: 'Solomon Islands' },
  { value: Country.SO, label: 'Somalia' },
  { value: Country.ZA, label: 'South Africa' },
  { value: Country.GS, label: 'South Georgia' },
  { value: Country.ES, label: 'Spain' },
  { value: Country.LK, label: 'Sri Lanka' },
  { value: Country.SD, label: 'Sudan' },
  { value: Country.SR, label: 'Surilabel' },
  { value: Country.SJ, label: 'Svalbard and Jan Mayen' },
  { value: Country.SZ, label: 'Swaziland' },
  { value: Country.SE, label: 'Sweden' },
  { value: Country.CH, label: 'Switzerland' },
  { value: Country.SY, label: 'Syrian Arab Republic' },
  { value: Country.TW, label: 'Taiwan' },
  { value: Country.TJ, label: 'Tajikistan' },
  { value: Country.TZ, label: 'Tanzania' },
  { value: Country.TH, label: 'Thailand' },
  { value: Country.TL, label: 'Timor-Leste' },
  { value: Country.TG, label: 'Togo' },
  { value: Country.TK, label: 'Tokelau' },
  { value: Country.TO, label: 'Tonga' },
  { value: Country.TT, label: 'Trinidad and Tobago' },
  { value: Country.TN, label: 'Tunisia' },
  { value: Country.TR, label: 'Turkey' },
  { value: Country.TM, label: 'Turkmenistan' },
  { value: Country.TC, label: 'Turks and Caicos Islands' },
  { value: Country.TV, label: 'Tuvalu' },
  { value: Country.UG, label: 'Uganda' },
  { value: Country.UA, label: 'Ukraine' },
  { value: Country.AE, label: 'United Arab Emirates' },
  { value: Country.GB, label: 'United Kingdom', prioritized: true },
  { value: Country.US, label: 'United States', prioritized: true },
  { value: Country.UY, label: 'Uruguay' },
  { value: Country.UM, label: 'U.S. Minor Outlying Islands' },
  { value: Country.UZ, label: 'Uzbekistan' },
  { value: Country.VU, label: 'Vanuatu' },
  { value: Country.VE, label: 'Venezuela' },
  { value: Country.VN, label: 'Vietnam' },
  { value: Country.VG, label: 'Virgin Islands, British' },
  { value: Country.VI, label: 'Virgin Islands, U.S.' },
  { value: Country.WF, label: 'Wallis and Futuna' },
  { value: Country.EH, label: 'Western Sahara' },
  { value: Country.YE, label: 'Yemen' },
  { value: Country.ZM, label: 'Zambia' },
  { value: Country.ZW, label: 'Zimbabwe' },
];
