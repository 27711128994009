import React, { FC, SVGProps } from 'react';

interface EllipsisProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const Ellipsis: FC<EllipsisProps> = ({ title = 'More', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M400 256c0 26.5 21.5 48 48 48s48-21.5 48-48S474.5 208 448 208S400 229.5 400 256zM112 256c0-26.5-21.5-48-48-48S16 229.5 16 256S37.5 304 64 304S112 282.5 112 256zM304 256c0-26.5-21.5-48-48-48S208 229.5 208 256S229.5 304 256 304S304 282.5 304 256z"></path>
  </svg>
);

export default Ellipsis;
