import React, { FC, SVGProps } from 'react';

interface MessageCheckProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const MessageCheck: FC<MessageCheckProps> = ({ title = 'Mark as read', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M311.3 136.4L232.2 228.7L202.5 199c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94l48 48c4.5 4.5 10.62 7.031 16.97 7.031c.3125 0 .625 0 .9062-.0313C241.1 287.7 247.4 284.7 251.7 279.6l96-112c8.625-10.06 7.469-25.22-2.594-33.84C335.1 125.2 319.9 126.3 311.3 136.4zM447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.836 11.02 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM464 352c0 8.75-7.25 16-16 16h-160l-80 60v-60H64c-8.75 0-16-7.25-16-16V64c0-8.75 7.25-16 16-16h384c8.75 0 16 7.25 16 16V352z"></path>
  </svg>
);

export default MessageCheck;
