import React, { FC, SVGProps } from 'react';

interface BarsProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const Bars: FC<BarsProps> = ({ title = 'Navigation', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M424 392H23.1C10.8 392 0 402.8 0 415.1C0 429.2 10.8 440 23.1 440H424c13.2 0 24-10.8 24-23.1C448 402.8 437.2 392 424 392zM424 72H23.1C10.8 72 0 82.8 0 95.1S10.8 120 23.1 120H424c13.2 0 24-10.8 24-23.1S437.2 72 424 72zM424 232H23.1C10.8 232 0 242.8 0 256c0 13.2 10.8 24 23.1 24H424C437.2 280 448 269.2 448 256S437.2 232 424 232z"></path>
  </svg>
);

export default Bars;
