import React, { FC, SVGProps } from 'react';

interface LogoIconProps extends SVGProps<SVGSVGElement> {}

const LogoIcon: FC<LogoIconProps> = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 365.67 482.71"
    role="img"
    aria-labelledby="logo-icon-title"
    {...rest}
  >
    <title id="logo-icon-title">babel chat</title>
    <g>
      <g>
        <path d="M326.83,163.06l12,71.33S240.7,251.06,131.6,196.1l13.94-65.85S225.11,177.83,326.83,163.06Z" transform="translate(0)" fill="#10b981"/>
        <path d="M302.76,150.38l-6.53-63.85h0c-42.81,4.35-103.76-20.32-103.76-20.32l-14.23,50.61S215.59,148.57,302.76,150.38Z" transform="translate(0)" fill="#10b981"/>
        <circle cx="253.52" cy="31.38" r="31.38" fill="#10b981"/>
      </g>
      <path d="M364.83,341.23l-17.26-83.46c-71.18,7.42-156.76-18.12-204.09-35.15a39.57,39.57,0,0,0-50.31,22.87L44.36,371,0,482.71l122.2-85.32a39,39,0,0,1,27.47-6.83c54.11,7.29,131.77,2.44,180-1.91A39.55,39.55,0,0,0,364.83,341.23ZM158.65,304.75a5.72,5.72,0,0,1-5.72,5.72H139.11a5.72,5.72,0,0,1-5.71-5.72V282.44a5.71,5.71,0,0,1,5.71-5.71h13.82a5.71,5.71,0,0,1,5.72,5.71ZM230,329.6a5.72,5.72,0,0,1-5.71,5.72H210.45a5.72,5.72,0,0,1-5.72-5.72V307.29a5.72,5.72,0,0,1,5.72-5.71h13.82a5.71,5.71,0,0,1,5.71,5.71ZM301.31,351a5.7,5.7,0,0,1-5.71,5.71H281.78a5.71,5.71,0,0,1-5.71-5.71V328.7a5.72,5.72,0,0,1,5.71-5.72H295.6a5.71,5.71,0,0,1,5.71,5.72Z" transform="translate(0)" fill="#10b981"/>
    </g>
  </svg>
);

export default LogoIcon;
