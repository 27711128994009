import React, { FC, SVGProps } from 'react';

interface ArrowUpProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const ArrowUp: FC<ArrowUpProps> = ({ title = 'Send', ...rest }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    {...rest}
  >
    <title>{title}</title>
    <path fill="currentColor" d="M6.625 215.5l168-176C179.2 34.7 185.4 32.02 192 32.02s12.84 2.688 17.38 7.438l168 176c9.125 9.594 8.781 24.78-.8125 33.94c-9.5 9.156-24.75 8.812-33.94-.8125L216 115.9V456c0 13.25-10.75 23.1-23.1 23.1S168 469.3 168 456V115.9l-126.6 132.7C32.22 258.2 16.97 258.5 7.438 249.4C-2.156 240.2-2.5 225 6.625 215.5z"></path>
  </svg>
);

export default ArrowUp;
